import { Button, Center, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import skills from "../../../assets/skills.png";
import "../../../styles/components/hero.scss";

const Hero = ({ passion, setPassion, setOptions }) => {
	return (
		<>
			<Center mb={"12rem"} flexDirection={"column"} w={"full"} className="hero">
				<VStack
					w={{ base: "100%", lg: "50%" }}
					p={{ base: "0 8px", lg: "unset" }}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Heading className="hero__heading">
						Unlock Your Potential With Hybrid Training, Anywhere, Anytime
					</Heading>
					<Text className="hero__content">
						{/* Master Africa helps Masters with years of experience to turn
            available spaces such as homes, churches, lecture halls, parks,
            restaurants e.t.c into Master Training Centers (MTC) and connects
            them with Apprentices who find it hard to learn online. Start Your
            Search Today! */}
						{/* Search for Spaces and Creators to Create Content to Grow Your
            Business. Start Your Search Today! */}
						Discover a world of flexible training that combines live sessions,
						gamification and in-person training to unlock new skills and
						opportunities!
					</Text>
					<Button
						className="hero__action"
						h={"full"}
						onClick={() => window.location.replace("/#form")}
					>
						Sign up for free
					</Button>
				</VStack>
				<Image
					display={{ base: "none", lg: "block" }}
					m={"71px 0 89px 0"}
					src={skills}
				/>
				{/* <Heading className="hero__ending">
          A fun social way to meet Masters, Creative Space Owners {"(MIC’s)"}{" "}
          and Creators.
        </Heading>
      </Center>
      <Box w={"84%"} h={"75vh"} m={"3rem auto 12rem"}>
        <iframe
          style={{
            borderRadius: "10px",
            height: "100%",
            width: "100%",
          }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/mqHWl7ThGEE"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Box> */}
			</Center>
		</>
	);
};

export default Hero;
