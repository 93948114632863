import React from "react";
import logo from "../../../assets/svg/master-logo.svg";
import { BiX, BiListUl } from "react-icons/bi";
import {
  Box,
  Heading,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import "../../../styles/components/header.scss";
import Donate from "../../../components/Donate";
import AccountInput from "../../../components/AccountInput";

const Header = () => {
  return (
    <Box className="header">
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Link href={"/"}>
            <Image src={logo} w={"full"} />
          </Link>
        </Box>
        <Stack
          display={{ base: "none", lg: "flex" }}
          className="header__links"
          alignItems={"center"}
          direction={"row"}
          gap={"53px"}
        >
          <Link href="#copyright">Categories</Link>
          <Link href="#copyright">About Us</Link>
          <Link href="#copyright">Master</Link>
        </Stack>

        {/* <Stack
          display={{ base: "none", lg: "flex" }}
          gap={"20px"}
          alignItems={"center"}
          direction={"row"}
        >
          <Link>Sign In</Link>
          <Button
            className="header__action"
            background={"#029642"}
            borderRadius={"10px"}
            color={"white"}
          >
            Join Us
          </Button>
        </Stack> */}
        <Stack gap={"20px"} alignItems={"center"} direction={"row"}>
          <Donate>
            <Box>
              <Heading
                fontSize={{
                  md: "xl",
                }}
              >
                Account Name: Master Apprenticeship and Recruiting Technology
                Ltd
              </Heading>
              <AccountInput label={"UBA (USD)"} account={"3004094361"} />
              <AccountInput label={"UBA (NAIRA)"} account={"1026219724"} />
            </Box>
          </Donate>
          <Menu className="header__menu">
            {({ isOpen }) => (
              <>
                <MenuButton
                  className="header__menu__button"
                  variant={"unstyled"}
                  color={"blackAlpha.900"}
                  as={IconButton}
                >
                  {isOpen ? <BiX /> : <BiListUl />}
                </MenuButton>
                <MenuList>
                  <MenuItem>Categories</MenuItem>
                  <MenuItem>About Us</MenuItem>
                  <MenuItem>Master</MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Header;
