import React, { useMemo, useState } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Offer from "./components/Offer";
import Form from "./components/Form";
import Goals from "./components/Goals";
import Action from "./components/Action";
import Footer from "./components/Footer";
import PictureSlide from "./components/PictureSlide";
import Copyright from "./components/Copyright";
import { options as skills } from "../../constants";
import Donate from "../../components/Donate";
import { Box, Heading, Button } from "@chakra-ui/react";
import AccountInput from "../../components/AccountInput";

const slides1 = [
  {
    image: "/images/1.jpg",
    link: "",
  },
  {
    image: "/images/2.jpg",
    link: "",
  },
  {
    image: "/images/3.jpg",
    link: "",
  },
  {
    image: "/images/4.jpg",
    link: "",
  },
  {
    image: "/images/5.jpg",
    link: "",
  },
  {
    image: "/images/6.jpg",
    link: "",
  },
  {
    image: "/images/7.jpg",
    link: "",
  },
  {
    image: "/images/8.jpg",
    link: "",
  },
  {
    image: "/images/9.jpg",
    link: "",
  },
  {
    image: "/images/10.jpg",
    link: "",
  },
];

const Home = () => {
  const [passion, setPassion] = useState("");
  const [options, setOptions] = useState(skills);

  const props = useMemo(
    () => ({
      passion,
      options,
      setPassion,
      setOptions,
    }),
    [options, passion, setPassion, setOptions]
  );

  return (
    <>
      <Header />
      <Hero {...props} />
      <Form {...props} />

      <Offer />
      <PictureSlide
        data={slides1}
        caption={
          "Join the Upskill Africa Movement as we train millions of young people to learn a skill. It all starts with one person"
        }
      />
      <Box display="flex" my={10} justifyContent="center" mx="auto">
        <Donate
          trigger={
            <Button
            px={20}
            py={8}
              _focus={{
                background: "#00cf5a",
              }}
              _hover={{
                background: "#029642",
              }}
              color={"white"}
              background={"#029642"}
            >
              Donate
            </Button>
          }
        >
          <Box>
            <Heading
              fontSize={{
                md: "xl",
              }}
            >
              Account Name: Master Apprenticeship and Recruiting Technology Ltd
            </Heading>
            <AccountInput label={"UBA (USD)"} account={"3004094361"} />
            <AccountInput label={"UBA (NAIRA)"} account={"1026219724"} />
          </Box>
        </Donate>
      </Box>
      <Goals />
      <Action />
      <Footer />
      <Copyright />
    </>
  );
};

export default Home;
