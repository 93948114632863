import React, { useEffect, useMemo, useState } from "react";
import formImage from "../../../assets/form-image.png";
import Input, { Select } from "../../../components/Input";
import {
	Box,
	Center,
	Heading,
	Image,
	Stack,
	VStack,
	Text,
	Checkbox,
	HStack,
	Button,
	InputLeftElement,
	Tooltip,
	Flex,
	IconButton,
} from "@chakra-ui/react";
import "../../../styles/components/form.scss";
import {
	countries,
	// creatorRoles,
	defaultCountry,
	manualSkills,
	types,
} from "../../../constants";
import SuccessStory from "./SuccessStory";
import { useFormik } from "formik";
import { useRegister } from "../../../api/user";
import * as yup from "yup";
import Modal from "../../../components/Modal";
import Progress from "../../../components/Progress";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";
import { HiOutlineInformationCircle } from "react-icons/hi";
import PictureSlide from "./PictureSlide";

const defaultModal = {
	status: "success",
	isOpen: false,
	title: "",
	content: "",
};

const slides2 = [
	{
		image: "/images/11.jpg",
		link: "",
	},
	{
		image: "/images/12.jpg",
		link: "",
	},
	{
		image: "/images/13.jpg",
		link: "",
	},
	{
		image: "/images/11.jpg",
		link: "",
	},
];

const Form = ({ options, passion }) => {
	const [modal, setModal] = useState(defaultModal);
	const { register, registerUserResults } = useRegister();
	const [isFocused, setIsFocused] = useState(false);
	const loading = registerUserResults.isLoading;
	const [isOpen, setIsOpen] = useState(false);

	const isMobile = window.innerWidth <= 768;

	const validationSchema = yup.object().shape({
		firstName: yup.string().required("Please enter your First Name"),
		lastName: yup.string().required("Please enter your Last Name"),
		email: yup
			.string()
			.required("Please enter your Email Address")
			.email("Please enter a valid Email Address"),
		type: yup.string().required("Please select your Account Type"),
		phone: yup.object().shape({
			number: yup
				.string()
				.required("Please enter your Phone Number")
				.test(
					"Checks if phone number is valid",
					"Please enter a valid phone number",
					(value) => Boolean(value && isValidPhoneNumber(value, iso))
				),
			code: yup.string().required("Please choose your calling code"),
		}),
	});

	const {
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
	} = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			phone: {
				code: defaultCountry.code,
				number: "",
			},
			micAddress: "",
			techSkill: "",
			manualSkill: "",
			type: "",
			creatorRole: "",
			hasMTC: undefined,
		},
		validationSchema,

		onSubmit: async (values) => {
			try {
				const { data } = await register({
					...values,
					type: values.type === types[0] ? "Student" : values.type,
				});

				if (data?.ok) {
					setModal({
						isOpen: true,
						status: "success",
						title: "Congratulations",
						content: data?.message,
					});
				}
			} catch (error) {
				const message = error?.response?.data
					? error?.response?.data?.message
					: error?.message;

				setModal({
					isOpen: true,
					status: "error",
					title: "Something went wrong",
					content: message,
				});
			}
		},
	});

	const iso = useMemo(
		() => countries.find((country) => country.code === values.phone.code)?.iso,
		[values.phone.code]
	);

	const handlePhoneChange = (event) => {
		const formated = new AsYouType(iso).input(event.target.value);
		setFieldValue("phone.number", formated);
	};

	useEffect(() => {
		if (passion) {
			setFieldValue("techSkill", passion);
			window.location.replace("/#form");
		}
		// eslint-disable-next-line
	}, [options.length, setFieldValue]);

	return (
		<Box id={"form"} mb={"193px"}>
			<Modal {...modal} onClose={() => setModal(defaultModal)} />
			<Stack direction={"row"} gap={"0"}>
				<Box
					display={{ base: "none", lg: "block" }}
					bg={"#34A853"}
					borderRadius={"15px"}
					borderLeftRadius={"0"}
					flex={1}
					p={"16px 18px 17px 0"}
				>
					<Center
						border={"5px solid #FFFFFF"}
						borderRadius={"15px"}
						borderLeftRadius={"0"}
						m={"0"}
						p={"0"}
						borderLeft={"none"}
					>
						<Image
							h={"full"}
							src={formImage}
							transform={"translateX(1%) scale(1.2)"}
						/>
					</Center>
				</Box>
				<VStack flex={1} className="form">
					<Heading className="form__heading">Register with Us</Heading>
					<Box display={["flex", "grid"]} className="form__inputs">
						<Input
							maxW={"unset"}
							w={"full"}
							label={"First Name"}
							variant={"flushed"}
							isRequired
							name={"firstName"}
							value={values.firstName}
							onChange={handleChange}
							onBlur={handleBlur}
							isInvalid={Boolean(touched.firstName && errors.firstName)}
							error={touched.firstName && errors.firstName}
						/>
						<Input
							maxW={"unset"}
							w={"full"}
							label={"Last Name"}
							variant={"flushed"}
							isRequired
							name={"lastName"}
							value={values.lastName}
							onChange={handleChange}
							onBlur={handleBlur}
							isInvalid={Boolean(touched.lastName && errors.lastName)}
							error={touched.lastName && errors.lastName}
						/>
						<Input
							maxW={"unset"}
							w={"full"}
							variant={"flushed"}
							label={"Email Address"}
							type="email"
							name="email"
							isRequired
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							isInvalid={Boolean(touched.email && errors.email)}
							error={touched.email && errors.email}
						/>
						<Input
							maxW={"unset"}
							w={"full"}
							variant={"flushed"}
							className="phone-input"
							type="tel"
							label="WhatsApp Number"
							name="phone.number"
							value={values.phone.number}
							onChange={handlePhoneChange}
							onBlur={handleBlur}
							isInvalid={Boolean(
								touched.phone && (errors.phone?.number || errors.phone?.code)
							)}
							error={
								touched.phone && (errors.phone?.number || errors.phone?.code)
							}
							placeholder="Enter your Phone Number"
						>
							<InputLeftElement
								w="25%"
								h="30px"
								onClick={() => {
									setIsFocused(true);
								}}
							>
								<Select
									w="100%"
									h="35px"
									name="phone.code"
									variant={"filled"}
									value={values.phone.code}
									onChange={(event) => {
										handleChange(event);
										setIsFocused(false);
									}}
								>
									{countries.map((country) => (
										<option
											style={{
												paddingInlineStart: "15px",
											}}
											key={country.iso}
											value={country.code}
										>
											{isFocused ? country.country : country.code}
										</option>
									))}
								</Select>
							</InputLeftElement>
						</Input>
						<Box className="form__checkboxes">
							<Text>How will you use Master?</Text>
							<HStack className="form__checkboxes__wrap">
								{types.map((type) => (
									<Checkbox
										key={type}
										onChange={() => setFieldValue("type", type)}
										isChecked={values.type === type}
									>
										{type}
									</Checkbox>
								))}
							</HStack>
						</Box>
						{values.type ? (
							values.type === types[1] ? (
								<Box>
									{/* <Select
                  maxW={"unset"}
                  w={"full"}
                  label={"Creator Roles"}
                  variant={"flushed"}
                  name={"creatorRole"}
                  placeholder={"Please select your profession as a creator"}
                  value={values.creatorRole}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={Boolean(touched.creatorRole && errors.creatorRole)}
                  error={touched.creatorRole && errors.creatorRole}
                  options={creatorRoles.map((option) => option.trim())}
                /> */}
								</Box>
							) : (
								<>
									<Select
										maxW={"unset"}
										w={"full"}
										label={"Tech Skill"}
										variant={"flushed"}
										name={"techSkill"}
										placeholder={"Please select a tech skill"}
										value={values.techSkill}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={Boolean(touched.techSkill && errors.techSkill)}
										error={touched.techSkill && errors.techSkill}
										options={options.map((option) => option.trim())}
									/>
									<Select
										maxW={"unset"}
										w={"full"}
										label={"Manual Skill"}
										variant={"flushed"}
										placeholder={"Please select a manual skill"}
										name={"manualSkill"}
										options={manualSkills.map((option) => option.trim())}
										value={values.manualSkill}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={Boolean(
											touched.manualSkill && errors.manualSkill
										)}
										error={touched.manualSkill && errors.manualSkill}
									/>
								</>
							)
						) : null}
						<Box className="form__checkboxes">
							<Tooltip
								hasArrow
								isOpen={isMobile ? isOpen : undefined}
								placement="top-start"
								closeDelay={500}
								label="A Master Training Center is any accessible space that can be used for learning a skill."
							>
								<Flex gap={"5px"} alignItems={"center"}>
									<Text>Do you have a Master Training Center (MTC)?</Text>
									<IconButton
										mb={"20px"}
										onClick={() => {
											if (isMobile) setIsOpen(!isOpen);
										}}
										as={HiOutlineInformationCircle}
										size={"xs"}
										variant={"link"}
									/>
								</Flex>
							</Tooltip>
							<HStack className="form__checkboxes__wrap">
								{[
									{ label: "Yes", value: true },
									{ label: "No", value: false },
								].map((answer) => (
									<Checkbox
										key={answer.label}
										onChange={() => setFieldValue("hasMTC", answer.value)}
										isChecked={values.hasMTC === answer.value}
									>
										{answer.label}
									</Checkbox>
								))}
							</HStack>
						</Box>
						{values?.hasMTC && (
							<Input
								maxW={"unset"}
								w={"full"}
								variant={"flushed"}
								label={"MTC Address"}
								name="micAddress"
								isRequired
								value={values.micAddress}
								onChange={handleChange}
								onBlur={handleBlur}
								isInvalid={Boolean(touched.micAddress && errors.micAddress)}
								error={touched.micAddress && errors.micAddress}
								helperText={
									"A Master Training Center is any accessible space that can be used for learning a skill."
								}
							/>
						)}
					</Box>
					<Button
						isLoading={loading}
						onClick={handleSubmit}
						className="form__action"
					>
						Register
					</Button>
				</VStack>
			</Stack>
			<Progress loading={loading} />

			<Box>
				<Box p={40} px={10} pb={20}>
					<Heading
						fontSize={["4xl", "6xl"]}
						fontWeight="bold"
						textAlign="center"
					>
						Upskill Africa Scholarship Program. Proudly Supported By Master
						Africa
					</Heading>
					<Heading
						fontSize={["sm", "lg"]}
						fontWeight="regular"
						textAlign="center"
					>
						Compete and Win a Scholarship Program to Learn a Skill of
						your choice
					</Heading>
				</Box>

				<Box w={"84%"} h={"75vh"} m={"3rem auto 10px"}>
					<iframe
						style={{
							borderRadius: "10px",
							height: "100%",
							width: "100%",
						}}
						width="560"
						height="315"
						src="https://www.youtube.com/embed/0TrC-pb-_CI?si=K4A5HMLqNg5hKmLg"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					></iframe>
				</Box>
			</Box>

			<PictureSlide
				data={slides2}
				caption={"Earn money renting out training spaces."}
			/>

			<Heading
				className=""
				mt={"2em"}
				fontSize={["3xl", "5xl"]}
				fontWeight="bold"
				textAlign={"center"}
			>
				Learning Should Be Fun. Over 300,000 views on TikTok.
			</Heading>

			<Box w={"84%"} position="relative" minH={"75vh"} m={"3rem auto 12rem"}>
				<blockquote
					className="tiktok-embed"
					cite="https://www.tiktok.com/@masterforafrica/video/7366123632554183954"
					data-video-id="7366123632554183954"
					style={{
						// maxWidth: "650px",
						minWidth: "325px",
					}}
				>
					<section>
						<a
							target="_blank"
							title="@masterforafrica"
							rel="noreferrer"
							href="https://www.tiktok.com/@masterforafrica?refer=embed"
						>
							@masterforafrica
						</a>{" "}
						Teacher Chike teaches at www.masterafrica.com
					</section>{" "}
				</blockquote>{" "}
			</Box>

			<Heading
				className=""
				mt={"2em"}
				fontSize={["3xl", "5xl"]}
				fontWeight="bold"
				textAlign={"center"}
			>
				Get Access To Apprentices and Get Paid to Teach a Skill.
			</Heading>

			<Box w={"84%"} h={"75vh"} m={"3rem auto 12rem"}>
				<iframe
					style={{
						borderRadius: "10px",
						height: "100%",
						width: "100%",
					}}
					width="560"
					height="315"
					src="https://youtube.com/embed/gm-Z3O8d5fQ?si=vn3ZhP6oczp74eT6"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
				></iframe>
			</Box>
			<Box p="4">
				<Heading fontSize={["3xl", "5xl"]} fontWeight="bold" textAlign="center">
					Success Story
				</Heading>
				<SuccessStory
					description={
						"I found the perfect apprentice for my business through Master Africa. The platform is a game-changer!"
					}
					name={"- Chidi,"}
					occupation={"Phone and Laptop Engineer"}
				/>
			</Box>
		</Box>
	);
};

export default Form;
