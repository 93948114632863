import React from "react";
import "../../../styles/components/hero.scss";
import "../../../styles/components/footer.scss";
import logo from "../../../assets/svg/master-logo.svg";
import {
  Box,
  Button,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  VStack,
} from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box className="footer">
      <Box display={{ base: "flex", lg: "grid" }} className="footer__wrap">
        <Box className="footer__image">
          <Image src={logo} />
        </Box>
        <VStack className="footer__links">
          <Link className="footer__link">Find passion</Link>
          <Link className="footer__link">Skills</Link>
        </VStack>
        <VStack className="footer__links">
          <Link className="footer__link">Categories</Link>
          <Link className="footer__link">Master</Link>
        </VStack>
        <VStack className="footer__cta">
          <Heading className="footer__cta__heading">Join our Community</Heading>
          <InputGroup className="footer__cta__input hero__input">
            <Input w={"full"} h={"full"} placeholder="email" />
            <InputRightElement h={"full"} className="hero__join">
              <Button
                h={"full"}
                onClick={() =>
                  window.open(
                    "https://380ece5e.sibforms.com/serve/MUIEALmqNOoETzmFv0XD9CwVxosFCxNogQAhVl3QzFLRh10Pf6z71yuYIyTqUFJOKE6scAFxi387FqrSTy_zDM12R574QIgY5mkSnB0yCTyNM0-0PiCsRnqx3WmdIb2xtjomooRAdcj0-8Zqei6qwd3BniJhJKkDEZ22RzC5Q12qpJQDxz0Ojk8A-5lC2PMhXGMP6KbtF-2Yts5t",
                    "_blank"
                  )
                }
              >
                Join
              </Button>
            </InputRightElement>
          </InputGroup>
        </VStack>
      </Box>
    </Box>
  );
};

export default Footer;
